<template>
  <div class="large-page">
    <breadcrumbs />

    <user-form
      class="mt-4 mt-md-8 contain-form-width mx-auto"
      :is-loading="isLoading"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")
const UserForm = () => import(/* webpackChunkName: "user-form" */ "@/components/common/UserForm.vue")

export default {
  name: "AdminUserCreate",

  components: {
    Breadcrumbs,
    UserForm
  },

  data: () => ({
    isLoading: false
  }),

  methods: {
    async handleSubmit(formData) {
      this.isLoading = true

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        const response = await axios({
          url: "/api/admin/users",
          method: "POST",
          data: {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            password: formData.password,
            master_password: formData.masterPassword,
            role: formData.role,
            credit_type: formData.creditType,
            company_id: formData.company_id,
            is_demo: formData.isDemo,
            is_active: formData.status,
            is_whitelisted: formData.isWhitelisted,
            can_use_api: formData.canUseAPI,
            ip_addresses: formData.IPAddresses,
            country: formData.country,
            currency: formData.currency,
            valid_from: formData.valid_from || null,
            valid_to: formData.valid_to || null,
            metas: formData.metas,
            allowed_services: formData.allowedServices
          }
        })

        // Make transaction related rows
        for (const transaction of Object.values(formData.transactions)) {
          // If the amount is smaller than 0
          if (transaction.amount <= 0) {
            // Skip this one
            continue
          }

          // Make a network request
          await axios({
            url: "/api/admin/users/" + response.data.id + "/balance",
            method: "POST",
            data: {
              ...transaction,
              amount: parseFloat(transaction.amount, 10) * constants.conversionRate
            }
          })
        }

        // update the local store
        this.$store.dispatch("admin/users/fetchItems")

        // Redirect and let the user know
        this.$router.replace({
          name: "Admin/Users/Index"
        })

        // show an alert message
        this.$store.dispatch("toasts/add", {
          text: "User has been added."
        })

        // refresh profile data with deducted balance
        window.dispatchEvent(new CustomEvent('fetchAuthUserProfile', {
          detail: {
            module: "influencerInsight",
            origin: "influencerInsight/fetchProfile"
          }
        }))
      } catch (error) {
        logger({ type: "Admin/Users/Create Error", error })

        // The keys for error messages
        const keys = Object.keys(error.response?.data?.errors || {})

        // If there are any items in this key, read the message
        const errorMessage = keys.length ? error.response.data.errors[keys[0]][0] : (error.response?.data?.message || "An error occurred")

        // Show the error message
        this.$store.dispatch("toasts/add", {
          text: errorMessage
        })
      } finally {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    }
  }
}
</script>
